export default async function verifyReCaptcha(token) {
  const verify = await fetch('/api/recaptcha', {
    method: 'post',
    body: token,
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(new Error('Something went wrong!'));
    })
    .then((data) => {
      return data.success; // Bool
    })
    // eslint-disable-next-line no-console
    .catch((error) => console.log(error));

  return verify;
}
