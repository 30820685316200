import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import styles from './Date.module.scss';

const Date = ({ date, latest }) => (
  <div className={styles.date}>
    {latest ? <span className={styles.date__label}>latest</span> : null}
    <span className={styles.date__display}>
      <time dateTime={date}>{moment(date).format('MMMM DD, YYYY')}</time>
    </span>
  </div>
);

Date.propTypes = {
  date: PropTypes.string.isRequired,
  latest: PropTypes.bool,
};

Date.defaultProps = {
  latest: false,
};

export default Date;
