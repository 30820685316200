// These functions normalize the mishandling of
// topic slugs on the legacy site and the normalizing
// of all topic names from the UE service.

const normalizeTopicSlug = (topic) => {
  const removeAccents = (letter) => {
    let replacement = letter;
    const from = 'àáäâèéëêìíïîòóöôùúüûñçěščřžýúůďťň';
    const to = 'aaaaeeeeiiiioooouuuuncescrzyuudtn';

    for (let i = 0, l = from.length; i < l; i += 1) {
      replacement = replacement.replace(
        new RegExp(from.charAt(i), 'g'),
        to.charAt(i)
      );
    }

    return replacement;
  };

  const normalizedSlug = topic
    .toLowerCase()
    .replace(/./g, removeAccents)
    // Remove stop words
    .replace(/\b(and|or|a|an|but)\b/gi, '')
    // Remove apostrophes
    .replace(/'/g, '')
    // Remove special characters
    .replace(/[^a-z0-9]+/g, '-')
    // Remove double dashes
    .replace(/(^-|-$)/g, '');

  if (normalizedSlug === topic) {
    return false;
  }

  return normalizedSlug;
};

// normalizeTopicLabel() is to handle topic
// navigation link text. Since all topics
// are returned from the UE service as lowercase
// we have to handle punctuation and account for
// edge case terms with special punctuation/capitalization.

// Stakeholders have been made aware that new
// exceptions will require a code change.
const normalizeTopicLabel = (topic) => {
  if (topic === 'covid-19' || topic === 'lgbtq') {
    return topic.toUpperCase();
  }

  if (topic.indexOf('-') > -1) {
    return topic
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join('-');
  }

  return topic
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');
};

export { normalizeTopicSlug, normalizeTopicLabel };
