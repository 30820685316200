/* eslint-disable import/prefer-default-export */
const isParentActive = (type, path, category, shortName) => {
  if (
    type === 'archives' &&
    (path.endsWith(`/${category}/${shortName}/archives`) ||
      path.endsWith(`/${category}/${shortName}/archives/`))
  ) {
    return true;
  }
  if (
    type === 'topics' &&
    (path.endsWith(`/${category}/${shortName}`) ||
      path.endsWith(`/${category}/${shortName}/`))
  ) {
    return true;
  }
  return false;
};

export { isParentActive };
